import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Button, Grid, Menu, MenuItem, TextField, Dialog, DialogTitle, DialogContent, CircularProgress } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { displayDateFormatShort, fieldDateFormat, backendDateFormat } from '../../../constants';
import { globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
const ProjectStatusPopUp = ({params, projectStatus, toggleModalFn, getResponseBack}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [project, setProject] = useState(projectStatus.data);
  const [error, setError] = useState({});
  const onChangeProjectStatus = () => {
    let validationInputs = {
      reason:project.status.id !== 7 ? project?.reason?.trim()?.length ? '':'Please enter Reason' :'',
      end_date:project.status.id === 7 ? project.end_date && moment(project.end_date).isValid() ? '': 'Please enter Clouse Date' :''
    };
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
      setLoading(true);
      globalPutService(`technical/project/${projectStatus.slug}/change-status/`, project)
      .then(response => {
        if(checkApiStatus(response)){
          enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
          toggleModalFn();getResponseBack();
        }else{
          enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
        }
        setLoading(false);
      })
    }else{
      setError(validationInputs);
    }
  }
  return(
    <Dialog
      open={projectStatus.modal}
      maxWidth={'md'}
      className='project-management-modal'
    >
      <DialogTitle id="scroll-dialog-title">
        {project.status.id === 8 ? 'Do you want to Cancel Project?' : project.status.id === 0 ? 'Do you want to Archive Project?' : project.status.id === 7 ? 'Do you want to Close Project?' : 'Do you want to Open Project?'}
      </DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ width: '380px' }}>
          {project.status.id === 7 ?
            <div>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      required
                      id="date_of_closure"
                      label="Date Of Closure"
                      format={fieldDateFormat}
                      fullWidth
                      clearable={true}
                      disableFuture
                      error={error?.end_date}
                      helperText={error?.end_date||''}
                      InputLabelProps={{ shrink: true }}
                      value={project?.end_date||null}
                      inputVariant="outlined"
                      onChange={(data, value) => {setProject({ ...project, end_date: data ? moment(data).format(backendDateFormat) : data }); setError({...error,'end_date':''}) }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </div> : null
          }
          {project.status.id === 0 || project.status.id === 8 || project.status.id === 3 ?
            <div>
              <TextField
                required
                id="reason"
                label="Reason"
                value={project.reason}
                multiline
                maxRows={4}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                error={error?.reason}
                helperText={error?.reason||''}
                onChange={(e) => setProject({ ...project, reason: e.target.value })}
                onFocus={() => setError({...error,'reason':''})}
                variant="outlined"
              />
            </div> : null
          }
          <div style={{ textAlign: 'center', margin: '15px 0' }}>
            <ul className='list-inline'>
              <li className='list-inline-item'>
                <Button disabled={isLoading} onClick={onChangeProjectStatus} color='secondary' size='small' variant='contained'>
                  {isLoading ? <CircularProgress size={20} /> : 'YES'}
                </Button>
              </li>
              <li className='list-inline-item'>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='contained'>NO</Button>
              </li>
            </ul>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default withRouter(ProjectStatusPopUp);
