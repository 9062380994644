import React from 'react';
import { ToolTipHover } from '../../../shared';

const AircraftBluePrints = (props) => {

  let status = 'blue';
  switch(props.sectionDetail['status']){
    case 1 :
      status = 'poor'
    break;
    case 2 :
     status = 'average'
      break;
    case 3:
     status = 'good'
      break;
    default:
    break;
  }


  if(props.sectionDetail.finding_count && props.sectionDetail.finding_count > 0 ){
    status = 'poor'
  }

  const getFindings = (evt) =>{
    if(props.findingClickable !== false){
      evt.preventDefault();
      evt.stopPropagation();
      props.auditSectionInfo();
    }
  }
  
  return (
    <div className="indicater-image position" style={props.findingClickable === false ? {cursor: 'default'} : {}} onClick={(evt) => getFindings(evt)} id={props.bluePrintId==3?'flight-indicater'+props.sectionDetail['id']:([8, 9].includes(props.bluePrintId) && [16, 8, 32].includes(props.sectionDetail['id'])) ? 'indicater_' + props.sectionDetail['id'] :'indicater'+props.sectionDetail['id']}>
      <div className="indicater" title="" style={props.formInfo&&props.formInfo.current_form && props.formInfo.current_form.status != undefined && props.formInfo.current_form.status != null && props.formInfo.current_form.name=='T007'&& props.formInfo.current_form.status.id==7&&status!='poor'?{opacity:'0'}:{opacity:'1'}} id={[11].includes(props.bluePrintId) && props?.model?.id == 12 ?'model12':''}>
        <i className={"fa fa-circle "+ status} id={ 'section'+  props.sectionDetail['id']}>

          <span className="indicater-number" >
          {
            props.sectionDetail['finding_count'] == 0  ? '' :  props.sectionDetail['finding_count'] > 0 ?
            props.sectionDetail['finding_count'] : props.index >=0? props.index+1 : props.sectionDetail['id']
          }
          </span>
        </i>
        <ToolTipHover placement="top" tagetId={ 'section'+ props.sectionDetail['id']}>
          {props.sectionDetail['name']}
        </ToolTipHover>
      </div>
    </div>
  )
}
export default AircraftBluePrints;
